/**
 * useVisibility Composable
 *
 * Purpose:
 * This composable provides the ability to determine the visibility of components based on e.g. the user's subscription status.
 *
 *
 * Usage Example:
 * ```javascript *
 *  const { isChildBlokVisible } = useVisibility();
 *  const visible = isChildBlokVisible(childBlok);
 * ```
 */

export function useVisibility() {
  const meStore = useMeStore()
  const authStore = useAuthStore()

  // This function is used to determine if a child blok should be visible or not.
  const isChildBlokVisible = (childBlok: any) => {
    if (typeof childBlok.visibility === 'undefined') {
      return true
    }
    else if (childBlok.hidden) {
      return false
    }
    else {
      const visibility = childBlok.visibility
      switch (visibility) {
        // Visible to all users
        case 'all':
          return true
        // Not visible to any user
        case 'none':
          return false
        // Only visible to users with active subscription
        case 'only_with_active_abo':
          if (meStore.me.has_active_subscription) { return true }
          else { return false }

        // Only visible to users without active subscription
        case 'only_without_active_abo':
          if (meStore.me.has_active_subscription) { return false }
          else { return true }

        // Only visible to users who are logged in
        case 'only_logged_in_user':
          if (authStore.isAuthenticated) { return true }
          else { return false }

        // Default: Always visible
        default:
          return true
      }
    }
  }

  // Expose the computed property for consumption in components
  return {
    isChildBlokVisible,
  }
}
